export const pageImages =[
    {   id: 1,
        title: "Background Headshot",
        url: "https://lh3.googleusercontent.com/pw/AP1GczMK-ocUPMtyj0Vb3t5EmTjXgzYfZQGA_XDXYoeej0y3op3mpw40tI8zhN_04hRGhJVk9jaytYsOc_j2tn1rzVWBL1tIDBo7mkWDFih832_IKOwr4mIt2JcuomvQnkrO-yBBRHBm71VN-l3cUCstKu7j=w2196-h2130-s-no-gm?authuser=0"},
    {   id: 2,
        title: "About Headshot",
        url: "https://lh3.googleusercontent.com/pw/AP1GczOwu3XJLlcJVsBsGUGH-LZHvXUKqoI7RLHmZq_W0LS47WrvDhScT51wzlAqqH0O0EJxBYLImb55OkcrXsiDHUl86ElgiEdUX5ExGxFFtFOhhoUmKDBo1PVOKDSB_0an_ZvjjOeAm_Lq7PHPFkU69vQC=w1390-h2130-s-no-gm?authuser=0"},
    {   id: 3,
        title: "Contact Headshot",
        url: "https://lh3.googleusercontent.com/pw/AP1GczOIy-eLjJq5AmRmXp3IpWk5EdylbWBN5_qKxEJcYiT_Mk3rFmzM6kZDU0v8rwOl9Mwg7LXc49xjWGhCf-12VSbDlIC8CuqHvmEzuKov2RgZlPgTIZiWO8gzhKwH_V2_xyNX9neXkglf5_YRwI4_zmku=w1420-h2130-s-no-gm?authuser=0"},
    ];